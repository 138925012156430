import React from 'react';
import '../css/About.css'

function About() {
    return (
        <div id="about">
        <h1>Maybe I haven't filled this out yet. Maybe I'm highly enigmatic and mysterious. Who knows.</h1>
        </div>
    );
}

export default About;